import { NextPage } from "next";
import Head from "next/head";
import { connect } from "react-redux";

import ContactsComponent from "../components/contacts";
import withAppLayout from "../hocs/withAppLayout";

const ContactsPage: NextPage = () => {
  return (
    <>
      <Head>
        <title>TitleDock | Contacts</title>
        <meta name="description" content="TitleDock Contacts" />
      </Head>
      <ContactsComponent />
    </>
  );
};

export default connect()(withAppLayout(ContactsPage));
